var MantleCarousel = MantleCarousel || {};
var MantleWindow = MantleWindow || {};
var prodcat = prodcat || {};
var site = site || {};

(function ($, Unison) {
  const enableMobileCarouselProductFull = false;

  const slidesToShow = 5;
  const slidesToShowResponsive = 7;

  const productBriefClass = 'product-brief__shade-picker';
  const productFullClass = 'product-full__shadepicker';

  function _setSmooshColor(skuId, $product) {
    var $smoosh = $('.js-product-smoosh', $product);
    var sku;

    if (skuId) {
      sku = prodcat.data.getSku(skuId);
      if (sku && sku.HEX_VALUE_STRING) {
        $smoosh.css('background-color', sku.HEX_VALUE_STRING);
      }
    }
  }

  $(document).on('click', '.product-shade-picker__shade_family', function () {
    var $menu = $(this);
    var $product = $(this).closest('.js-product');
    var $menuLinks = $('.product-shade-picker__shade_family', $product);

    $menuLinks.removeClass('active');
    $menu.addClass('active');

    var $slider = $('.product-shade-picker__shades', $product);

    var selectedFamily = $menu.data('shade-family');
    var $shades = $('.js-product-shade', $product);

    // Create a reference to the active shade and remove the active class before filtering the shades.
    // That way the old active shade doesn't return if it's no longer valid in the new shade family.
    var $prevActiveShade = $('.js-product-shade.active', $product);
    $shades.removeClass('active');

    $shades.each(function (i, obj) {
      var $shade = $(obj);
      var _family = $shade.data('shade-family');
      var $shadeItem = $shade.parent(); // shades have a wrapper

      if (selectedFamily === 'all') {
        $shadeItem.removeClass('shade-hidden');
        return;
      }
      if (_family && _.includes(_family.split(','), selectedFamily)) {
        $shadeItem.removeClass('shade-hidden');
        return;
      }
      // process hidden shades
      $shadeItem.addClass('shade-hidden');
    });

    $menu.trigger('shadePicker:visible-shade-change');

    // *
    // * Early returns if the active shade exists in the selected family.
    // * The shade should remain active with no change.
    // *
    var familyOfActiveShade = $prevActiveShade.data('shade-family');

    if (selectedFamily === 'all') {
      handleShadeClick.call($prevActiveShade);
      $slider.trigger('shadePicker:visible-shade-change');
      return;
    }

    if (familyOfActiveShade && _.includes(familyOfActiveShade.split(','), selectedFamily)) {
      handleShadeClick.call($prevActiveShade);
      $slider.trigger('shadePicker:visible-shade-change');
      return;
    }

    // *
    // * When selecting a shade family, if the previously active shade is not in this new family,
    // * Replace the active shade to the first in the new family.
    // *
    const $replacementShade = $('.js-shade-picker-item:not(.shade-hidden)', $product)
      .first()
      .find('.js-product-shade');
    handleShadeClick.call($replacementShade);
    $slider.trigger('shadePicker:visible-shade-change');
  });

  function handleShadeClick(e) {
    var $shade = $(this);
    var skuId = $shade.data('sku');
    var productDelay = 50;

    if (!skuId) {
      return;
    }

    var sku = prodcat.data.getSku(skuId);

    if (!sku || $shade.hasClass('active')) {
      return;
    }

    var skuBaseId = sku.SKU_BASE_ID;
    var $product = $shade.closest('.js-product');
    var $shadeLabel = $('.js-product-shade-picker-label', $product);
    var $allShadeDots = $('.js-product-shade', $product);

    // Toggle the active state of the selected shades
    $shade.closest('.js-product-shade-picker').find('.js-product-shade').removeClass('active');

    $allShadeDots.removeClass('active');

    $shade.addClass('active');
    $shadeLabel.animate({ opacity: 0 }, 0, 'linear', function () {
      $shadeLabel.text($shade.attr('title'));
      $shadeLabel.animate({ opacity: 1 }, 750, 'linear');
    });

    setTimeout(() => {
      $product.trigger('product.skuSelect', [skuBaseId]);
    }, productDelay);
  }

  $(document).on('click', '.js-product-shade', handleShadeClick);

  /*
   * When activating a shade from the selectbox, the newly active shade might not be in the current family.
   * If the newly active shade is not in the current active shade family, then default to "all".
   */
  $(document).on('shadePicker:ensureActiveFamily', function (event, $product) {
    var $all_link = $('.product-shade-picker__shade_family[data-shade-family="all"]', $product);
    var selectedFamily = $('.product-shade-picker__shade_family.active').data('shade-family');
    var familyOfActiveShade = $('.js-product-shade.active', $product).data('shade-family');

    if (selectedFamily === 'all') {
      return;
    }

    if (familyOfActiveShade && _.includes(familyOfActiveShade.split(','), selectedFamily)) {
      return;
    }

    $all_link.trigger('click');
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);

    $product.triggerHandler('shadePicker:selectShade', [skuBaseId]);
  });

  $('.js-product-shade').on('mouseenter', function () {
    var $shade = $(this);
    var $product = $shade.closest('.js-product');
    var skuBaseId = $shade.data('sku');

    $shade.trigger('shadePickerShade:hover-in', [skuBaseId]);
    _setSmooshColor(skuBaseId, $product);
  });

  $(document).on('mouseleave', '.js-product-shade', function () {
    var $shade = $(this);
    var $product = $shade.closest('.js-product');
    var skuBaseId = $shade.data('sku');

    $shade.trigger('shadePickerShade:hover-out', [skuBaseId]);
    _setSmooshColor(skuBaseId, $product);
  });

  $(document).on('shadePicker:visible-shade-change', '.js-product-shade-picker', function () {
    var $shadepicker = $(this);
    var $product = $shadepicker.closest('.js-product');
    var $visibleShades = $('.js-product-shade:visible', $shadepicker);
    var $allShadeItems = $('.js-shade-picker-item', $shadepicker);
    var visibleShadeCount = $visibleShades.length;

    $shadepicker.removeClass('has-missing-shades');

    var productId = $product.data('product-id');
    var prodData = prodcat.data.getProduct(productId);

    if (!prodData.skus) {
      return;
    }

    var totalSkus = prodData.skus.length;
    var missingSkus = totalSkus - visibleShadeCount;

    if (missingSkus > 0) {
      $shadepicker.addClass('has-missing-shades');
    }

    // if this class is set. that means that all shades are visible. so ntohing
    // to calculate. we use visible so css can be what defines the visiblity
    if (!$shadepicker.hasClass('show-shade-overflow')) {
      var notCssVisibilityHidden = 0;

      // note :visible only works with display none
      $allShadeItems.each(function (i, obj) {
        var $shade_item = $(obj);

        if ($shade_item.css('visibility') !== 'hidden') {
          notCssVisibilityHidden++;
        }
      });
      if ($allShadeItems.length > notCssVisibilityHidden) {
        $shadepicker.addClass('has-shade-overflow');
      }
    }

    $('.js-show-shade-overflow', $shadepicker).on('click', function (e) {
      e.preventDefault();
      $shadepicker.toggleClass('show-shade-overflow');
    });

    $('.js-missing-shades', $shadepicker).text(missingSkus);
  });

  Drupal.behaviors.productShadePicker = {
    observer: null,
    setupObserver: function () {
      var self = this;

      if (self.observer !== null) {
        return;
      }
      var handler = function (entries) {
        _.each(entries, function (entry) {
          if (!entry.isIntersecting) {
            return;
          }

          var elem = entry.target;

          self.attachReal(elem);
        });
      };
      var options = {
        root: null,
        rootMargin: '0px',
        threshold: [0.1]
      };

      self.observer = new IntersectionObserver(handler, options);
    },
    attach: function ($context) {
      /*
       * Lazy load the shade picker
       */
      var self = this;
      var highlightOutStockShade = false;

      this.setupObserver();

      var $shadepickers = $('.js-product-shade-picker', $context);

      $shadepickers.each(function (i, obj) {
        self.observer.observe(obj);
      });

      highlightOutStockShade = $shadepickers.hasClass('highlight-out-stock-shade');

      if (highlightOutStockShade) {
        $.each(prodcat.data.store.skus, function (index, product) {
          // Key - Out of Stock(2), Sold Out (7)
          if ([2, 7].includes(product.INVENTORY_STATUS)) {
            $(".js-product-shade[data-sku='" + product.SKU_ID + "']").addClass('cross-out-shade');
          }
        });
      }
    },

    // Route path for shade family selection.
    shadeFamilyRoute: '/sf/',

    // eslint-disable-next-line complexity
    matchShadeFamilyFromHash: function ($menuLinks) {
      var shadeFamilyRoute = this.shadeFamilyRoute;

      if (!window.location.hash || !shadeFamilyRoute || !$menuLinks || $menuLinks.length === 0) {
        return;
      }

      var hash = window.location.hash.substring(1);

      if (hash.substring(0, shadeFamilyRoute.length) !== shadeFamilyRoute) {
        return;
      }

      var parts = hash.substring(shadeFamilyRoute.length).split('/');
      var sfKey = parts[0];

      if (!sfKey) {
        return;
      }

      var matchInfo = {};
      var $match = null;

      $menuLinks.each(function (i, obj) {
        var $link = $(obj);
        var linkKey = $link.data('shade-family');

        if (linkKey === sfKey) {
          $match = $link;

          return false;
        }
      });

      if (!$match) {
        return null;
      }

      matchInfo.$matchedFamily = $match;

      // Also try to match shade if possible
      // So defaulting to sku in shade family would be
      // /sf/pink/sku/123
      if (parts.length === 3 && parts[1] === 'sku') {
        matchInfo.skuBaseId = parts[2];
      }

      return matchInfo;
    },

    attachReal: function (shadepickerEl) {
      var self = this;

      // no longer wait for onscreen
      self.observer.unobserve(shadepickerEl);

      var $shadepicker = $(shadepickerEl);
      var $product = $shadepicker.closest('.js-product');

      var invStatus = parseInt($product.attr('data-inventory-status'), 10);
      var productShadeGrayed = Drupal.settings.common.product_shade_grayed;

      if ((invStatus === 2 || invStatus === 5 || invStatus === 7) && !productShadeGrayed) {
        $product.parent().addClass('product-shade-disabled');
        if (site.translations && site.translations.product) {
          $product
            .parents('.product-grid__item')
            .attr('title', site.translations.product.temp_out_of_stock_msg);
        }
      }

      var bps = Unison?.fetch?.all() ?? {};
      var $slider = $('.product-shade-picker__shades', $shadepicker);
      $slider.on('shadePicker:visible-shade-change', function () {
        var $this = $(this);
        if ($this.hasClass('slick-initialized')) {
          $this.slick('unslick');
          self.initializeSlick($this);
        }
      });
      // *
      // * Always initialize carousel on product-brief
      // *
      if ($shadepicker.parent().hasClass(productBriefClass)) {
        self.initializeSlick($slider);
        if (!$product.data('disable-shade-carousel')) {
          $shadepicker.addClass('slider');
        }
      } else if ($shadepicker.parent().hasClass(productFullClass)) {
        // *
        // * If enabled, on product full, initialize carousel on mobile
        // *
        if (enableMobileCarouselProductFull) {
          $(`.${productFullClass}`).addClass('product-full__shadepicker-carousel');
          _initProductFullCarouselMobile();
          _onResizeToggleCarousel();
        }
      }

      function _initProductFullCarouselMobile() {
        const bp = Unison?.fetch?.now()?.width ?? '';
        if (bp !== bps.landscape) {
          if (!$slider.hasClass('slick-initialized')) {
            $shadepicker.addClass('slider');
            self.initializeSlick($slider);
          }
        } else {
          if ($slider.hasClass('slick-initialized')) {
            $slider.slick('unslick');
            $shadepicker.removeClass('slider');
          }
        }
      }

      function _onResizeToggleCarousel() {
        Unison.on('change', function () {
          _initProductFullCarouselMobile();
        });
      }

      $product.on('shadePicker:selectShade', function (e, skuBaseId) {
        var $activeShade = $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', $product);

        _setSmooshColor(skuBaseId, $product);

        $('select.js-sku-menu', $product).val(skuBaseId);

        $('.js-product-shade', $product).removeClass('active');
        $activeShade.addClass('active');

        // *
        // * If shadepicker is in a carousel and not product_brief,
        // * Ensure the active shade is centered when selected from the SelectBox.
        // *
        var isProductBrief = $shadepicker.parent().hasClass(productBriefClass);
        if ($shadepicker.hasClass('slider') && !isProductBrief) {
          $activeShade.filter(':not(.slick-cloned)').trigger('click');
        }
        if (!isProductBrief) {
          $product.trigger('shadePicker:ensureActiveFamily', [$product]);
        }
      });

      // See if we have a matching shade family route. If so, simulate click
      // then opt out of default shade selection.
      var $menuLinks = $('.product-shade-picker__shade_family', $product);
      var matchInfo = this.matchShadeFamilyFromHash($menuLinks);

      if (matchInfo) {
        if (matchInfo.skuBaseId) {
          $product.trigger('product.skuSelect', [matchInfo.skuBaseId, true]);
        }
        matchInfo.$matchedFamily.trigger('click');

        return;
      }

      // default to current skubaseid on load
      if ($product.data('sku-base-id')) {
        var skuBaseId = $product.data('sku-base-id');
        $product.triggerHandler('shadePicker:selectShade', [skuBaseId]);
      }

      $shadepicker.trigger('shadePicker:visible-shade-change');
    },

    initializeSlick: function ($shadepicker) {
      var self = this;
      var shadeItem = '.product-shade-picker__shade-item:not(.shade-hidden)';
      var inNestedCarousel = $shadepicker.closest('.js-product-carousel').length > 0;
      var settings = {
        infinite: true,
        appendArrows: $shadepicker.closest('.js-shadepicker'),
        autoplay: false,
        centerMode: true,
        centerPadding: '2px',
        focusOnSelect: true,
        lazyLoad: 'progressive',
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: slidesToShowResponsive
            }
          }
        ],
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        slide: shadeItem,
        swipe: !inNestedCarousel,
        swipeToSlide: !inNestedCarousel,
        rows: 0
      };

      $shadepicker
        .once()
        .on('init', function (event, slick) {
          // skuChange has a 50 ms delay on attrs update.
          setTimeout(function () {
            self.sliderGoToSelected(slick, $shadepicker, shadeItem);
          }, 50);
        })
        .on('afterChange', function () {
          // Shades which are cloned in an infinite carousel get destroyed
          // when activated and lose their classes
          var activeShade = $shadepicker.find('.js-product-shade.active').attr('data-sku');
          $shadepicker.find(`.js-product-shade[data-sku='${activeShade}']`).addClass('active');
        });

      if (!$shadepicker.hasClass('slick-initialized')) {
        $shadepicker.slick(settings);
      }
    },
    sliderGoToSelected: function (slick, $shadepicker, shadeItem) {
      var selectedSku = $shadepicker.closest('.js-product').attr('data-sku-base-id');
      var $defaultShadeItem = $shadepicker
        .find(`.js-product-shade[data-sku=SKU${selectedSku}]`)
        .closest('.js-shade-picker-item');
      // initialSlide doesn't work with responsive config, so have to set it here.
      var isScrollable = slick.$slides.length > slick.options.slidesToShow;

      if (isScrollable) {
        // Trigegr click to handle centerMode.
        $defaultShadeItem.filter(':not(.slick-cloned)').trigger('click');
      }
      $defaultShadeItem.find('.js-product-shade').addClass('active');
    }
  };
})(jQuery, Unison);
